export const almostSchool = [
    {
        src: "mybday.jpg",
        date: "07/15/2022",
        desc: "Thank you for celebrating w/ me"
    },
    {
        src: "mybday2.jpg",
        date: "07/15/2022",
        desc: "And you made me cake!!!"
    },
    {
        src: "sushidate1.jpg",
        date: "07/16/2022",
        desc: "Squish"
    },
    {
        src: "sushidate2.jpg",
        date: "07/16/2022",
        desc: "Tteokbokki?!?!"
    },
    {
        src: "sushidate3.jpg",
        date: "07/16/2022",
        desc: "😊🍜"
    },
    {
        src: "highway1.jpg",
        date: "07/16/2022",
        desc: "Remember the biker?"
    },
    {
        src: "ft-eating.jpg",
        date: "07/20/2022",
        desc: "Yummers"
    },
    {
        src: "sleep4.jpg",
        date: "07/20/2022",
        desc: "Post snack nap"
    },
    {
        src: "sleep3.jpg",
        date: "08/01/2022",
        desc: "Squish nap"
    },
    {
        src: "sleep2.jpg",
        date: "08/03/2022",
        desc: "Lots of nap before school apparently"
    },
    {
        src: "cute6.jpg",
        date: "08/05/2022",
        desc: "Very cute"
    },
]