export const extracurriculars = [
    {
        title: "ACM",
        artist: "Member",
        src: "acm.png",
        href: "/playlist/acm"
    },
    {
        title: "Robotics at Iowa",
        artist: "Software Engineer",
        src: "robotics.png",
        href: "/playlist/robotics"
    },
]