<template>
    <div class="section full-height" id="song-page">
        <TopbarVue urlExtended
            backgroundGradient="linear-gradient(to bottom, rgba(255, 200, 0, 255), rgba(213, 175, 24, 200));" />
        <div class="container-flex justify-content-left song-page-header">
            <img src="../../static/images/courser.png" />
            <div class="column">
                <p class="playlist-category">SONG</p>
                <h1 class="playlist-title">Courser</h1>
                <strong class="playlist-creator">Liao Zhu</strong>
            </div>
        </div>
        <MusicNav link="https://devpost.com/software/courser-85wqix" />
        <div class="container-flex justify-content-left song-desc">
            <p>
                <a href="https://devpost.com/software/courser-85wqix" target="_blank">Courser</a> was one of the most fun
                projects I've ever had building. It was a 36-hour hackathon project that I built with my friends: <a
                    href="https://www.patrickrfoster.com/" target="_blank">Patrick F.</a>, <a
                    href="https://github.com/engineswap" target="_blank">Ilya T.</a>, and <a
                    href="https://github.com/GautamSharda" target="_blank">Gautam S.</a>
                <br />
                <br />
                There was some doubt going into the hackathon **cough cough** Patrick **cough cough** that we would be able
                to win anything with our idea. But we ended up winning <strong>1st place</strong> overall amongst the 76
                teams that participated and I couldn't have been more happy to be apart of such a great team!
                <br />
                <br />
                Courser enables students to upload their degree audit which contains information on what courses they've
                taken,
                what courses they need to take, and how many semester hours they have left.
                Courser then uses this information to recommend real courses and schedules that the student can take to
                fulfill their degree
                requirements through a large language model powered by OpenAI.
                Not only that, but the student can also follow up with personal requirements, for example: "I don't want to
                take any classes before 8am" or "Please don't give me Professor X".
                We managed to get over 10,000 real future courses by scraping the University of Iowa's course catalog as
                well utilizing
                the school's course API.
                <br />
                <br />
                This was the first time I've ever used OOP in Javascript, and also the first time I've used NodeJS/Express
                in a large scale setting before. I mainly focused on the backend, but to be honest I had a ton of help from
                my teammates.
                I learned a lot from this project and I'm excited to see what else I can
                build with NodeJS/Express in the future.
            </p>
        </div>
    </div>
</template>
<script>
import MusicNav from "../components/MusicNav.vue";
import TopbarVue from "../components/TopbarVue.vue";

export default {
    created() {
        document.title = "Liao Zhu - Courser"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        MusicNav,
        TopbarVue
    },
}
</script>
<style scoped>
.section {
    background-image: linear-gradient(to bottom, rgb(255, 221, 0, 80), black);
}

.song-page-header {
    background-image: linear-gradient(to bottom, rgba(255, 200, 0, 255), rgb(255, 221, 0, 80));
}
</style>