<template>
    <div class="section full-height" id="song-page">
        <TopbarVue urlExtended
            backgroundGradient="linear-gradient(to bottom, rgba(255, 200, 0, 255), rgba(213, 175, 24, 200));" />
        <div class="container-flex justify-content-left song-page-header">
            <img src="../../static/images/uigrades.png" />
            <div class="column">
                <p class="playlist-category">SONG</p>
                <h1 class="playlist-title">UIGrades</h1>
                <strong class="playlist-creator">Liao Zhu</strong>
            </div>
        </div>
        <MusicNav link="https://uigrades.vercel.app/" />
        <div class="container-flex justify-content-left song-desc">
            <p>
                <a href="https://uigrades.vercel.app" target="_blank">UIGrades</a> is a web application where I was given the opportunity to be the 
                sole developer for my university's course grade distribution to help students discover over 10,000+ courses and their grade distributions at the University
                of Iowa.
                <br />
                <br />
                This was a tedious journey but I learned a ton along the way. The app has already received a ton of traction in just a few days of release! 
                Huge thanks to the Undergraduate Student Government at Iowa for giving me this opportunity to make such an impactful application.
                <br />
                <br />
                To see UIGrades in action, visit: <a href="https://uigrades.vercel.app"
                    target="_blank">https://uigrades.vercel.app</a>
            </p>
        </div>
    </div>
</template>
<script>
import MusicNav from "../components/MusicNav.vue";
import TopbarVue from "../components/TopbarVue.vue";

export default {
    created() {
        document.title = "Liao Zhu - UIGrades"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        MusicNav,
        TopbarVue
    },
}
</script>
<style scoped>
.section {
    background-image: linear-gradient(to bottom, rgb(255, 221, 0, 80), black);
}

.song-page-header {
    background-image: linear-gradient(to bottom, rgba(255, 200, 0, 255), rgb(255, 221, 0, 80));
}
</style>