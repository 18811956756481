<template lang="">
    <div class="section full-height" id="projects-page">
        <TopbarVue urlExtended backgroundGradient="linear-gradient(to bottom, rgba(118,132,148,255), rgba(102,114,127,255));"/>
        <div class="container-flex justify-content-left playlist-header" id="liaozhu-header">
            <img src="../../static/images/home.png" style="object-fit: contain"/>
            <div class="column">
                <p class="playlist-category">PLAYLIST</p>
                <h1 class="playlist-title">Content</h1>
                <p class="playlist-desc">View the different sections of my portfolio!</p>
                <strong class="playlist-creator">Liao Zhu</strong>
            </div>
        </div>
        <MusicNav liked="true" />
        <div class="playlist-keys container-flex">
            <div class="container-flex justify-content-left">
                <p class="key-index">#</p>
                <p class="key-title">TITLE</p>
            </div>
            <p class="key-album">ALBUM</p>
            <p class="key-time"><fa icon="fa-solid fa-clock"/></p>
        </div>
        <hr class="playlist-hr"/>
        <div class="playlist-songs">
            <div class="container-flex song-container" @mouseover="handleSongHover(0)" @mouseleave="handleSongLeave(0)">
                    <div class="container-flex song-header">
                        <p class="song-index">1</p>
                        <RouterLink to="/" style="cursor: default"><fa id="playlist-song-play-icon" class="playlist-song-play" icon="fa-solid fa-play"/></RouterLink>
                        <img src="../../static/images/home.png"/>
                        <div class="container-flex column justify-content-left song-info">
                            <RouterLink to="/" class="song-title">Home</RouterLink>
                            <div class="container-flex">
                                <p class="song-languages">Liao Zhu</p>
                            </div>
                        </div>
                    </div>
                    <RouterLink to="/" class="song-code">Open</RouterLink>
                    <p class="song-time">4:16</p>
            </div>
            <div class="container-flex song-container" @mouseover="handleSongHover(1)" @mouseleave="handleSongLeave(1)">
                    <div class="container-flex song-header">
                        <p class="song-index">2</p>
                        <RouterLink to="/user" style="cursor: default"><fa id="playlist-song-play-icon" class="playlist-song-play" icon="fa-solid fa-play"/></RouterLink>
                        <img src="../../static/images/profile.png"/>
                        <div class="container-flex column justify-content-left song-info">
                            <RouterLink to="/user" class="song-title">Profile</RouterLink>
                            <div class="container-flex">
                                <p class="song-languages">About me</p>
                            </div>
                        </div>
                    </div>
                    <RouterLink to="/user" class="song-code">Open</RouterLink>
                    <p class="song-time">2:44</p>
            </div>
            <div class="container-flex song-container" @mouseover="handleSongHover(2)" @mouseleave="handleSongLeave(2)">
                    <div class="container-flex song-header">
                        <p class="song-index">3</p>
                        <RouterLink to="/playlist/projects" style="cursor: default"><fa id="playlist-song-play-icon" class="playlist-song-play" icon="fa-solid fa-play"/></RouterLink>
                        <img src="../../static/images/projects.png"/>
                        <div class="container-flex column justify-content-left song-info">
                            <RouterLink to="/playlist/projects" class="song-title">Projects</RouterLink>
                            <div class="container-flex">
                                <p class="song-languages">Vue,</p>
                                <p class="song-languages">JavaScript,</p>
                                <p class="song-languages">Python,</p>
                                <p class="song-languages">HTML/CSS,</p>
                                <p class="song-languages">Java</p>
                            </div>
                        </div>
                    </div>
                    <RouterLink to="/playlist/projects" class="song-code">Open</RouterLink>
                    <p class="song-time">1:52</p>
            </div>

            <div v-for="(section, index) in sections" :key="section.title">
                <div class="container-flex song-container" @mouseover="handleSongHover(index + 3)" @mouseleave="handleSongLeave(index + 3)">
                    <div class="container-flex song-header">
                        <p class="song-index">{{ index + 4 }}</p>
                        <a :href="section.code" target="_blank" style="cursor: default"><fa id="playlist-song-play-icon" class="playlist-song-play" icon="fa-solid fa-play"/></a>
                        <img :src="require(`../../static/images/${section.src}`)"/>
                        <div class="container-flex column justify-content-left song-info">
                            <a :href="section.code" target="_blank" class="song-title">{{ section.title }}</a>
                            <div class="container-flex">
                                <p v-for="(language, index) in section.languages" :key="language" class="song-languages">{{ language[0] }}<span v-if="index != section.languages.length - 1">, </span></p>
                            </div>
                        </div>
                    </div>
                    <a :href="section.code" target="_blank" class="song-code">Open</a>
                    <p class="song-time">{{ section.time }}</p>
                </div>
            </div>
        </div>
</div>
</template>
<script>
import MusicNav from "../components/MusicNav.vue";
import TopbarVue from "../components/TopbarVue.vue";

export default {
    created(){
        document.title = "Liao Zhu - Content"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    data() {
        return {
            sections: [
                {
                    title: "Resume",
                    src: "resume.png",
                    languages: [["Experiences"], ["Extracuriculars"], ["Courses"], ["Projects"]],
                    code: "../liaozhu.pdf",
                    time: "2:33",
                },
                {
                    title: "Github",
                    src: "github.png",
                    languages: [["Projects"], ["Code"], ["Deployments"]],
                    code: "https://github.com/liaozhuzhu",
                    time: "1:59",
                },
                {
                    title: "Linkedin",
                    src: "linkedin.png",
                    languages: [["Expereinces"], ["Skills"], ["Interests"]],
                    code: "https://www.linkedin.com/in/liao-zhu/",
                    time: "3:31"
                },
                {
                    title: "Email",
                    src: "email.png",
                    languages: [["Email Me"]],
                    code: "mailto:liao.zhu.cs@gmail.com",
                    time: "2:45"
                },
            ]
      }
    },
    components: {
        MusicNav,
        TopbarVue
    },
    methods: {
        toggleAccountDropdown() {
            this.accountIsShowing = !this.accountIsShowing;
            if (this.accountIsShowing) {
                document.getElementById("caret-up").style.display="flex";
                document.getElementById("caret-down").style.display="none";
                document.getElementById("account-dropdown-container").style.display="flex";
            } else {
                document.getElementById("caret-up").style.display="none";
                document.getElementById("caret-down").style.display="flex";
                document.getElementById("account-dropdown-container").style.display="none";
            }
        },
        togglePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity=1;
        },
        closePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity=0;
        },
        handleSongHover(index) {
            document.getElementsByClassName("playlist-song-play")[index].style.display="flex";
            document.getElementsByClassName("song-index")[index].style.opacity="0";
        },
        handleSongLeave(index) {
            document.getElementsByClassName("playlist-song-play")[index].style.display="none";
            document.getElementsByClassName("song-index")[index].style.opacity="0.6";
        }
    }
}
</script>
<style scoped>

    .section {
        background-image: linear-gradient(to bottom, rgba(48,54,60,255), rgba(39,42,47,255));
    }

    #liaozhu-header {
        background-image: linear-gradient(to bottom, rgba(102,114,127,255), rgba(64,73,81,255));
    }
</style>