<template>
    <div class="section full-height" id="song-page">
        <div class="container-flex column artist-header">
            <TopbarVue urlExtended />
            <div class="artist-intro-header">
                <div class="container-flex justify-content-left">
                    <fa icon="fa-solid fa-square-check"/>
                    <p style="">Verified Artist</p>
                </div>
                <h1 class="artist-title">AIS SWE Intern</h1>
                <p class="artist-listeners">January 2023 - May 2023</p>
            </div>
        </div>

        <ArtistNav link="https://its.uiowa.edu/about/ais"/>
        
        <div class="container-flex justify-content-left song-desc">
            <p>As a Software Engineering Intern for my school's <a href="https://its.uiowa.edu/about/ais" target="_blank">ITS - Administrative System Information department</a>. I worked on several projects that helped refine my skills.
            <br/>
            <br/>
            For this position, I have converted and implemented legacy Java code to a Java script using Gradle. I've also contributed to the development of the school's ethics form system.
            <br/>
            <br/>
            Probably the most exciting of all was being able to work on the school's component library where I learned a lot about VueJS and was able to develop and publish several of my own components.
            </p>
      </div>
    </div>
</template>
<script>
import ArtistNav from "../components/ArtistNav.vue";
import TopbarVue from "../components/TopbarVue.vue";

export default {
    created() {
        document.title = "Liao Zhu - ITS Administrative Information Systems Software Engineering Intern"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        ArtistNav,
        TopbarVue
    },
    methods: {
        toggleAccountDropdown() {
        this.accountIsShowing = !this.accountIsShowing;
        if (this.accountIsShowing) {
          document.getElementById("caret-up").style.display="flex";
          document.getElementById("caret-down").style.display="none";
          document.getElementById("account-dropdown-container").style.display="flex";
        } else {
          document.getElementById("caret-up").style.display="none";
          document.getElementById("caret-down").style.display="flex";
          document.getElementById("account-dropdown-container").style.display="none";
        }
      },
    }
    
}
</script>
<style scoped>
    .section {
        background-image: linear-gradient(to bottom, rgb(255, 239, 117), rgb(0, 0, 0));
    }

    .artist-header {
        background-image: url("../../static/images/itsbanner.png");
    }
</style>