export const summer = [
    {
        src: "cute2.jpg",
        date: "05/07/2022",
        desc: "✌️"
    },
    {
        src: "coupledrawing.jpg",
        date: "05/07/2022",
        desc: "Too accurate"
    },
    {
        src: "desmoines.jpg",
        date: "06/01/2022",
        desc: "Banana split (p.c your mom)"
    },
    {
        src: "garden.jpg",
        date: "06/03/2022",
        desc: "Arboretum time"
    },
    {
        src: "garden2.jpg",
        date: "06/03/2022",
        desc: ":)"
    },
    {
        src: "garden3.jpg",
        date: "06/03/2022",
        desc: "Waa"
    },
    {
        src: "garden4.jpg",
        date: "06/03/2022",
        desc: "🌷"
    },
    {
        src: "facemask1.jpg",
        date: "06/03/2022",
        desc: "Back when my face was awful"
    },
    {
        src: "facemask2.jpg",
        date: "06/03/2022",
        desc: "🧟"
    },
    {
        src: "canes.jpg",
        date: "06/06/2022",
        desc: "Our last day together :("
    },
    {
        src: "doublechin.jpg",
        date: "06/11/2022",
        desc: "FTing you made it bearable"
    },
    {
        src: "cute5.jpg",
        date: "06/24/2022",
        desc: "Hanging out with Anna"
    },
    {
        src: "sleep6.jpg",
        date: "06/29/2022",
        desc: "Napping again 🙄"
    },
]