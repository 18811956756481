<template>
    <div class="section full-height" id="song-page">
        <div class="container-flex column artist-header">
            <TopbarVue urlExtended />
            <div class="artist-intro-header">
                <div class="container-flex justify-content-left">
                    <fa icon="fa-solid fa-square-check"/>
                    <p style="">Verified Artist</p>
                </div>
                <h1 class="artist-title" style="font-size: 4rem;">SWE Intern</h1>
                <p class="artist-listeners">May 2023 - August 2023</p>
            </div>
        </div>
        <ArtistNav link="https://www.collinsaerospace.com/"/>
        <div class="container-flex justify-content-left song-desc">
            <p>
                In late May 2023, I started as a Software Engineering Intern for <a href="https://www.collinsaerospace.com/" target="_blank">Collins Aerospace</a>.
                <br/>
                <br/>
                For this position, I will be working on the <a href="https://www.airbus.com/en/products-services/commercial-aircraft/passenger-aircraft/a350-family" target="_blank">Airbus A350</a> Avionics Systems development for the IMO team. I successfully enabled remote accessibility on the avionic testing rigs in our labs, while working within an agile framework. 
                This involved collaborating with teams located in France and India which really made me embrace and learn agile principles. 
                <br/>
                <br/>
                To see more of what I've done during my time at Collins, view my <a href="../liaozhu.pdf" target="_blank">resume</a>!
            </p>
      </div>
    </div>
</template>
<script>
import ArtistNav from "../components/ArtistNav.vue";
import TopbarVue from "../components/TopbarVue.vue";
export default {
    created() {
        document.title = "Liao Zhu - Collins Aerospace Software Engineer Intern"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        ArtistNav,
        TopbarVue
    },  
    methods: {
        togglePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity=1;
        },
        closePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity=0;
        },
        toggleAccountDropdown() {
        this.accountIsShowing = !this.accountIsShowing;
        if (this.accountIsShowing) {
          document.getElementById("caret-up").style.display="flex";
          document.getElementById("caret-down").style.display="none";
          document.getElementById("account-dropdown-container").style.display="flex";
        } else {
          document.getElementById("caret-up").style.display="none";
          document.getElementById("caret-down").style.display="flex";
          document.getElementById("account-dropdown-container").style.display="none";
        }
      },
    }
    
}
</script>
<style scoped>
    .section {
        background-image: linear-gradient(to bottom, white, rgb(0, 0, 0));
    }

    .artist-header {
        background-image: url("../../static/images/collinsbanner.png");
    }
</style>