export const spring = [
    {
        src: "haircut.jpg",
        date: "02/23/2022",
        desc: "First ever haircut by you"
    },
    {
        src: "cute.jpg",
        date: "03/01/2022",
        desc: "Walk to graphic design"
    },
    {
        src: "cute3.jpg",
        date: "03/02/2022",
        desc: "IMU parking ramp date"
    },
    {
        src: "cute4.jpg",
        date: "03/02/2022",
        desc: "IMU parking ramp date pt. 2"
    },
    {
        src: "sunset.jpg",
        date: "03/02/2022",
        desc: "So pretty, the sunset's cool too"
    },
    {
        src: "heart.jpg",
        date: "03/11/2022",
        desc: "🥰"
    },
    {
        src: "airpods.jpg",
        date: "04/03/2022",
        desc: "Couldn't have done it w/o you"
    },
    {
        src: "sleep1.jpg",
        date: "04/28/2022",
        desc: "Little nap"
    },

]