export const projects = [
    {
        title: "Courser",
        page: "/track/courser",
        src: "courser.png",
        languages: [["NextJS"], ["NodeJS"], ["OpenAI"], ["Express"], ["MongoDB"], ["Tailwind"]],
        code: "https://github.com/paaatrrrick/uiowa-courser/",
        time: "1:13",
    },
    {
        title: "EduQuest",
        page: "/track/eduquest",
        src: "eduquest.png",
        languages: [["AWS"], ["React.js"], ["Tailwind"], ["OpenAI"]],
        code: "https://github.com/lukemoenning/eduquest",
        time: "2:19",
    },
    {
        title: "UIGrades",
        page: "/track/uigrades",
        src: "uigrades.png",
        languages: [["Next.js"], ["React.js"], ["Vercel"], ["Tailwind"]],
        code: "https://github.com/acm-uiowa/uiowa_grades",
        time: "2:30",
    },
    {
        title: "SayIT!",
        page: "/track/sayit",
        src: "sayit.png",
        languages: [["React.js"], ["OpenAI"]],
        code: "https://github.com/liaozhuzhu/sayit",
        time: "1:42",
    },
    {
        title: "TikTok Video Generator",
        page: "/track/tiktok-video-generator",
        src: "tiktok.jpeg",
        languages: [["Python"], ["MoviePy"], ["ZenQuote API"]],
        code: "https://github.com/liaozhuzhu/TikTokQuoteVideoGenerator",
        time: "2:56",
    },
    {
        title: "Clask",
        page: "/track/clask",
        src: "clask.png",
        languages: [["Python"], ["Flask"], ["MySQL"], ["AssemblyAI"]],
        code: "https://github.com/liaozhuzhu/clask",
        time: "3:32",
    },
    {
        title: "My Portfolio",
        page: "/track/my-portfolio",
        src: "home.png",
        languages: [["Vue"]],
        code: "",
        time: "1:46",
    },
    {
        title: "QiT",
        page: "/track/qit",
        src: "qit.png",
        languages: [["Python"], ["Flask"], ["MySQL"], ["Jinja"]],
        code: "",
        time: "3:45",
    },
    {
        title: "UIowa Course Search Clone",
        page: "/track/uiowa-course-search-clone",
        src: "iowa.png",
        languages: [["Vue"], ["Maui API"]],
        code: "",
        time: "2:13",
    },
    {
        title: "Sorting Visualizer",
        page: "/track/sorting-visualizer",
        src: "sort.png",
        languages: [["JavaScript"], ["HTML/CSS"]],
        code: "https://github.com/liaozhuzhu/sort",
        time: "4:10",
    },
    {
        title: "Statipy",
        page: "/track/statipy",
        src: "statipy.png",
        languages: [["Python"], ["Discord API"], ["Spotify API"]],
        code: "",
        time: "3:20",
    },
]