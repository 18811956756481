export const winter = [
    {
        src: "bobadate3.jpg",
        date: "12/09/2022",
        desc: "Boba date!"
    },
    {
        src: "bobadate.jpg",
        date: "12/09/2022",
        desc: "The bear"
    },
    {
        src: "bobadate4.jpg",
        date: "12/09/2022",
        desc: "Smooch"
    },
    {
        src: "snowpanda.jpg",
        date: "12/28/2022",
        desc: "The Pine City snowbear"
    },
    {
        src: "snowpanda2.jpg",
        date: "12/28/2022",
        desc: "Gets more hugs than me"
    },
    {
        src: "snowcouch.jpg",
        date: "12/31/2022",
        desc: "New Years Eve w/ Porter!"
    },
    {
        src: "newyears.jpg",
        date: "12/31/2022",
        desc: "Happy New Year"
    },
    {
        src: "date2.jpg",
        date: "01/20/2023",
        desc: "Sao zi and Fried chicken noodle"
    },
    {
        src: "date3.jpg",
        date: "01/20/2023",
        desc: "Fried chicken > Sao zi"
    },
    {
        src: "date4.jpg",
        date: "01/20/2023",
        desc: "The waitress was super busy"
    },
]