<template>
    <div class="section full-height" id="song-page">
        <TopbarVue urlExtended backgroundGradient="linear-gradient(to bottom, rgb(46, 8, 196), rgb(25, 14, 100));"/>
        <div class="container-flex justify-content-left song-page-header">
            <img src="../../static/images/clask.png"/>
            <div class="column">
                <p class="playlist-category">SONG</p>
                <h1 class="playlist-title">Clask</h1>
                <strong class="playlist-creator">Liao Zhu</strong>
            </div>
        </div>
        <MusicNav link="https://github.com/liaozhuzhu/clask"/>
        <div class="container-flex justify-content-left song-desc">
            <p>Nobody wants the gut wretching feeling that happens when you're too slow to write down the notes to whatever the professor is talking about.
            <br/>
            <br/>
            So using Python Flask for the backend, and vanilla HTML/CSS frontend (with Jinja template engine), I made a full stack web app that allowed the user to record lectures, and when finished can be transcribed into text so the user can
            write down the notes whenever they want. If the user makes a clask account, they can opt in to save the transcripts to their personal dashboard. Saved transcripts can be shared, edited, and given a title and author.
            <br/>
            <br/>
            This was my second time ever attempting a full stack application so there are many things that I still haven't quite figured out. Ever since Heroku went paid, I haven't had clask re-hosted. If you would like to try it yourself feel free to
            visit <a target="_blank" href="https://github.com/liaozhuzhu/clask">https://github.com/liaozhuzhu/clask</a>, and view the <a href="https://github.com/liaozhuzhu/clask/blob/main/README.md" target="_blank">README</a>.
            </p>
      </div>
    </div>
</template>
<script>
import MusicNav from "../components/MusicNav.vue";
import TopbarVue from "../components/TopbarVue.vue";
export default {
    created() {
        document.title = "Liao Zhu - Clask"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        MusicNav,
        TopbarVue
    },
    methods: {
        togglePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity=1;
        },
        closePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity=0;
        },
        toggleAccountDropdown() {
        this.accountIsShowing = !this.accountIsShowing;
        if (this.accountIsShowing) {
          document.getElementById("caret-up").style.display="flex";
          document.getElementById("caret-down").style.display="none";
          document.getElementById("account-dropdown-container").style.display="flex";
        } else {
          document.getElementById("caret-up").style.display="none";
          document.getElementById("caret-down").style.display="flex";
          document.getElementById("account-dropdown-container").style.display="none";
        }
      },
    }
    
}
</script>
<style scoped>
    .section {
        background-image: linear-gradient(to bottom, rgb(27, 4, 80), black);
    }

    .song-page-header {
        background-image: linear-gradient(to bottom, rgb(46, 8, 196), rgb(25, 14, 100));
    }
</style>