<template>
    <div class="container-grid profile-cards-container">
        <a :href="track.url" target="_blank" class="container-flex column justify-content-left profile-cards"
            v-for="track in recentTracks" :key="track.src">
            <img :src="`${track.src}`" />
            <div class="container-flex column justify-content-left">
                <h1>{{ track.title }}</h1>
                <p id="recent-artist">{{ track.artist }}</p>
            </div>
            <fa class="play-icon container-flex" id="track-play-icon" icon="fa-solid fa-play" />
        </a>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "PlayingVue",
    data() {
        return {
            recentTracks: [],
        }
    },
    methods: {
        async getRecentlyPlayed() {
            try {
                const response = await axios.get('https://ws.audioscrobbler.com/2.0/?method=user.getrecenttracks&user=liaozhuzhu&limit=6&api_key=25edc6c4efea0c062a69a540f974de60&format=json', {
                    headers: {
                        'Accept': 'application/json;odata=verbose',
                    }
                });
                let track = response.data["recenttracks"]["track"];
                this.recentTracks = [];
                if (track.length == 7) {
                    for (let i = 1; i < track.length - 1; i++) {
                        this.recentTracks.push({
                            title: track[i]['name'],
                            artist: track[i]['artist']['#text'],
                            src: track[i]['image'][3]['#text'],
                            url: track[i]['url'],
                        })
                    }
                } else {
                    for (let i = 0; i < track.length - 1; i++) {
                        this.recentTracks.push({
                            title: track[i]['name'],
                            artist: track[i]['artist']['#text'],
                            src: track[i]['image'][3]['#text'],
                            url: track[i]['url'],
                        })
                    }
                }

            } catch (error) {
                console.error(error);
            }
        },
    },
    created: function () {
        this.getRecentlyPlayed();
        window.setInterval(() => {
            this.getRecentlyPlayed();
        }, 750);
    }
}
</script>
<style scoped>
.home-playlist-title {
    padding-left: 20px;
}

.profile-cards-container {
    grid-gap: 0;
    margin: 0;
    grid-template-columns: repeat(auto-fill, minmax(200px, 100px));
    grid-gap: 25px;
    padding-left: 20px;
    justify-content: left;
    width: 100%;
}

.profile-cards {
    background-color: rgba(24, 25, 24, 255);
    border-radius: 6px;
    gap: 25px;
    transition: 0.25s ease-in;
    position: relative;
}

.profile-cards:hover {
    background-color: #7d7d7d40 !important;
}

.profile-cards img {
    width: 175px;
    height: 175px;
    border-radius: 6px;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    filter: drop-shadow(0rem 1rem 1rem rgb(21, 21, 23));
}

.profile-cards div {
    gap: 10px;
    align-items: baseline;
    width: 200px;
    margin-bottom: 20px;
}

.profile-cards h1 {
    width: 150px;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-inline-start: 20px;
}

.profile-cards p {
    opacity: 0.6;
    font-size: 0.8rem;
    color: white;
}

#track-play-icon {
    position: absolute;
    opacity: 1;
    right: 0px;
    bottom: 100px;
    opacity: 0;
    transition: 0.2s ease-in;
}

.profile-cards:hover #track-play-icon {
    opacity: 1;
}

@media screen and (max-width: 1040px) {
    .home-playlist-container {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        padding-right: 25px;
    }
}
</style>