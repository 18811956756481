<template>
    <div class="section full-height" id="song-page">
        <TopbarVue urlExtended backgroundGradient="linear-gradient(to bottom, rgba(48,116,159,255), rgba(27,67,91,255));"/>
        <div class="container-flex justify-content-left song-page-header">
            <img src="../../static/images/sort.png"/>
            <div class="column">
                <p class="playlist-category">SONG</p>
                <h1 class="playlist-title">Sorting Visualizer</h1>
                <strong class="playlist-creator">Liao Zhu</strong>
            </div>
        </div>
        <MusicNav link="https://liaozhuzhu.github.io/sort/"/>
        <div class="container-flex justify-content-left song-desc">
            <p>I made this visualizer to get a better grasp of DOM manipulation and to improve my javascript skills.
            <br/>
            <br/>
            This project taught me a lot about DOM manipulation and also enhanced my knowledge on various sorting algorithms.
            <br/>
            <br/>
            To try it yourself, go to <a target="_blank" href="https://liaozhuzhu.github.io/sort/">https://liaozhuzhu.github.io/sort/</a> and if you want to see the code for yourself, go to 
            this <a target="_blank" href="https://github.com/liaozhuzhu/sort">this repository</a>.
            </p>
      </div>
    </div>
</template>
<script>
import MusicNav from "../components/MusicNav.vue";
import TopbarVue from "../components/TopbarVue.vue";

export default {
    created() {
        document.title = "Liao Zhu - Sorting Visualizer"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        MusicNav,
        TopbarVue
    },
    methods: {
        togglePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity=1;
        },
        closePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity=0;
        },
        toggleAccountDropdown() {
            this.accountIsShowing = !this.accountIsShowing;
            if (this.accountIsShowing) {
                document.getElementById("caret-up").style.display="flex";
                document.getElementById("caret-down").style.display="none";
                document.getElementById("account-dropdown-container").style.display="flex";
            } else {
                document.getElementById("caret-up").style.display="none";
                document.getElementById("caret-down").style.display="flex";
                document.getElementById("account-dropdown-container").style.display="none";
            }
        },
    }
    
}
</script>
<style scoped>
    .section {
        background-image: linear-gradient(to bottom, rgba(27,67,91,255), black);
    }

    .song-page-header {
        background-image: linear-gradient(to bottom, rgba(48,116,159,255), rgba(27,67,91,255));
    }
</style>