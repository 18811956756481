<template>
    <div class="section full-height" id="projects-page">
        <TopbarVue urlExtended backgroundGradient="linear-gradient(to bottom, rgba(12,44,86,255), rgba(12,44,86,255));"/>
        <div class="container-flex justify-content-left playlist-header" id="projects-header">
            <img src="../../static/images/acm.png"/>
            <div class="column">
                <p class="playlist-category">PLAYLIST</p>
                <h1 class="playlist-title">ACM</h1>
                <p class="playlist-desc">Association for Computing Machinery @ UIowa</p>
                <strong class="playlist-creator">Liao Zhu</strong>
            </div>
        </div>
        <MusicNav link="https://acm.org.uiowa.edu/"/>
        <div class="container-flex justify-content-left song-desc">
            <p>Starting Fall 2023, I will begin my first semester as the President of the <a href="https://acm.org.uiowa.edu/" target="_blank">Association for Computing Machinery</a> (ACM) at the University of Iowa.
            <br/>
            <br/>
            I'm very excited and grateful for this opportunity to lead such an amazing organization. I've been a part of ACM since my freshman year and have been able to meet so many amazing people and friends. At ACM we promote the growth of computer science and technology through workshops, hackathons, tech talks, interview prep, and events.
            <br/>
            <br/>
            ACM's biggest event, UICC, contains a variety of well established guest speakers as well as other events and prizes. UICC is held once every year and everyone is invited, so please come!
            </p>
      </div>
</div>
</template>
<script>
import MusicNav from "../components/MusicNav.vue";
import TopbarVue from "../components/TopbarVue.vue";

export default {
    created() {
        document.title = "Liao Zhu - ACM"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        MusicNav,
        TopbarVue
    },
    methods: {
        toggleAccountDropdown() {
            this.accountIsShowing = !this.accountIsShowing;
            if (this.accountIsShowing) {
                document.getElementById("caret-up").style.display="flex";
                document.getElementById("caret-down").style.display="none";
                document.getElementById("account-dropdown-container").style.display="flex";
            } else {
                document.getElementById("caret-up").style.display="none";
                document.getElementById("caret-down").style.display="flex";
                document.getElementById("account-dropdown-container").style.display="none";
            }
      },
    }
}
</script>
<style scoped>

#projects-header {
    background-image: linear-gradient(to bottom, rgba(12,44,86,255), rgba(9,27,51,255));
}

#projects-page {
    background-image: linear-gradient(to bottom, rgba(9,27,51,255), black);
}
    
</style>