<template>
    <div class="section full-height" id="song-page">
        <div class="container-flex column artist-header">
            <TopbarVue urlExtended />
            <div class="artist-intro-header">
                <div class="container-flex justify-content-left">
                    <fa icon="fa-solid fa-square-check"/>
                    <p style="">Verified Artist</p>
                </div>
                <h1 class="artist-title">Math Instructor</h1>
                <p class="artist-listeners">October 2021 - August 2022</p>
            </div>
        </div>
        <ArtistNav link="https://www.mathnasium.com/"/>
        <div class="container-flex justify-content-left song-desc">
            <p>I worked at <a href="https://www.mathnasium.com/" target="_blank">Mathnasium</a> starting October 2021 to August 2022. Prior to being an instructor, I was a soccer referee. Though refereeing allowed me to gain so many valuable skills such as leadership, 
                teamwork, and critical-fast thinking, it was much more stressful compared to being an instructor at Mathnasium.
            <br/>
            <br/>
            I will never forget the kids I got to meet and had the honor of helping (and sometimes they even helped me when my math was rusty 😅). Being an instructor was a life lesson I'll always cherish. 
            The ability to balance both fun and learning to not just one kid, but numerous kids at a time, was a challenge. However, overcoming this hurdle felt amazing, and seeing end results was always the best.
            <br/>
            <br/>
            Working at Mathnasium also allowed me to see what working outside of a soccer field was like, and also how many of the skills still applied.
            <br/>
            <br/>
            The ability to be a leader was something I always struggled with. Growing up with an older brother, I found myself becoming very dependent on others.
            Putting myself in a teaching position and leading the learning was something I thank refereeing for, as it allowed me to become more confident in myself 
            to lead in the right direction.
            <br/>
            <br/>
            The second skill that was crucial for being an instructor, was teamwork. Teamwork and communication go hand in hand and are very important to leading an 
            effective group. At Mathnasium we always had a team of 3-4 instructors at a time, boosting efficiency and productivity for the students.
            <br/>
            <br/>
            I wish I had started working at Mathnasium sooner before I started college, as I wouldn't be as busy as I am now 😔. 
            </p>
      </div>
    </div>
</template>
<script>
import ArtistNav from "../components/ArtistNav.vue";
import TopbarVue from "../components/TopbarVue.vue";

export default {
    created() {
        document.title = "Liao Zhu - Mathnasium Instructor"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        ArtistNav,
        TopbarVue
    },
    methods: {
        togglePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity=1;
        },
        closePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity=0;
        },
        toggleAccountDropdown() {
        this.accountIsShowing = !this.accountIsShowing;
        if (this.accountIsShowing) {
          document.getElementById("caret-up").style.display="flex";
          document.getElementById("caret-down").style.display="none";
          document.getElementById("account-dropdown-container").style.display="flex";
        } else {
          document.getElementById("caret-up").style.display="none";
          document.getElementById("caret-down").style.display="flex";
          document.getElementById("account-dropdown-container").style.display="none";
        }
      },
    }
    
}
</script>
<style scoped>
    .section {
        background-image: linear-gradient(to bottom, red, black);
    }

    .artist-header {
        background-image: url("../../static/images/mathnasiumbanner.png");
    }
</style>