<template>
    <div class="section full-height" id="song-page">
        <TopbarVue urlExtended
            backgroundGradient="linear-gradient(to bottom, rgba(114,87,176,255), rgba(67,51,103,255));" />
        <div class="container-flex justify-content-left song-page-header">
            <img src="../../static/images/home.png" />
            <div class="column">
                <p class="playlist-category">SONG</p>
                <h1 class="playlist-title">My Portfolio</h1>
                <strong class="playlist-creator">Liao Zhu</strong>
            </div>
        </div>
        <MusicNav link="/" notarget />
        <div class="container-flex justify-content-left song-desc">
            <p>My online portfolio verson 14,325,214. I wanted to make my portfolio more fun and less bland. One app I use
                all the time is Spotify. Whether I'm working, doing homework, going to the gym, or even walking, I'm always
                listening to Spotify. So this portfolio version is supposed to be a fun version of Spotify.
                <br />
                <br />
                To get more practice with VueJS, I used Vue to make this portfolio. This portfolio also displays the song
                I'm currently listening to, and the 5 most recent songs
                I've listened to on the <RouterLink to="/">home page</RouterLink>.
                <br />
                <br />
                Though this portfolio is supposed to be an online resume, check out my real resume here: <a
                    href="../../liaozhu.pdf" target="_blank">liaozhu.pdf</a>.
            </p>
        </div>
    </div>
</template>
<script>
import MusicNav from "../components/MusicNav.vue";
import TopbarVue from "../components/TopbarVue.vue";

export default {
    created() {
        document.title = "Liao Zhu - My Portfolio"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        MusicNav,
        TopbarVue
    },
    methods: {
        togglePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity = 1;
        },
        closePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity = 0;
        },
        toggleAccountDropdown() {
            this.accountIsShowing = !this.accountIsShowing;
            if (this.accountIsShowing) {
                document.getElementById("caret-up").style.display = "flex";
                document.getElementById("caret-down").style.display = "none";
                document.getElementById("account-dropdown-container").style.display = "flex";
            } else {
                document.getElementById("caret-up").style.display = "none";
                document.getElementById("caret-down").style.display = "flex";
                document.getElementById("account-dropdown-container").style.display = "none";
            }
        },
    }

}
</script>
<style scoped>
.section {
    background-image: linear-gradient(to bottom, rgba(67, 51, 103, 255), black);
}

.song-page-header {
    background-image: linear-gradient(to bottom, rgba(114, 87, 176, 255), rgba(67, 51, 103, 255));
}
</style>