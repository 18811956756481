<template>
    <div class="container-flex full-height column" style="position: relative;">
        <div class="lily-intro-container lily-inner-container" id="intro">
            <h1 class="page-title">For Lily ❤️ </h1>
        </div>
        <div class="lily-image-container lily-inner-container" id="image">
            <h1 class="page-title" id="valentines-title">Happy <span>Valentines</span> Day</h1>
            <br/>
            <br/>
            <div class="section-container">
                <h3 class="lily-section-title">Early Days</h3>
                <div class="image-gallery">
                    <div class="image-card" v-for="image in earlyDays" :key="image.src">
                        <img class="lily-image" :src="require(`../../static/lily-images/${image.src}`)"/>
                        <br/>
                        <br/>
                        <p class="date">{{image.date}}</p>
                        <p class="desc">{{image.desc}}</p>
                    </div>
                </div>
            </div>
            <div class="section-container">
                <h3 class="lily-section-title">The Rise</h3>
                <div class="image-gallery">
                    <div class="image-card" v-for="image in theRise" :key="image.src">
                        <img class="lily-image" :src="require(`../../static/lily-images/${image.src}`)"/>
                        <br/>
                        <br/>
                        <p class="date">{{image.date}}</p>
                        <p class="desc">{{image.desc}}</p>
                    </div>
                </div>
            </div>
            <div class="section-container">
                <h3 class="lily-section-title">Spring 2022</h3>
                <div class="image-gallery">
                    <div class="image-card" v-for="image in spring" :key="image.src">
                        <img class="lily-image" :src="require(`../../static/lily-images/${image.src}`)"/>
                        <br/>
                        <br/>
                        <p class="date">{{image.date}}</p>
                        <p class="desc">{{image.desc}}</p>
                    </div>
                </div>
            </div>
            <div class="section-container">
                <h3 class="lily-section-title">Summer 2022</h3>
                <div class="image-gallery">
                    <div class="image-card" v-for="image in summer" :key="image.src">
                        <img class="lily-image" :src="require(`../../static/lily-images/${image.src}`)"/>
                        <br/>
                        <br/>
                        <p class="date">{{image.date}}</p>
                        <p class="desc">{{image.desc}}</p>
                    </div>
                </div>
            </div>
            <div class="section-container">
                <h3 class="lily-section-title">Almost School</h3>
                <div class="image-gallery">
                    <div class="image-card" v-for="image in almostSchool" :key="image.src">
                        <img class="lily-image" :src="require(`../../static/lily-images/${image.src}`)"/>
                        <br/>
                        <br/>
                        <p class="date">{{image.date}}</p>
                        <p class="desc">{{image.desc}}</p>
                    </div>
                </div>
            </div>
            <div class="section-container">
                <h3 class="lily-section-title">School Start</h3>
                <div class="image-gallery">
                    <div class="image-card" v-for="image in schoolStart" :key="image.src">
                        <img class="lily-image" :src="require(`../../static/lily-images/${image.src}`)"/>
                        <br/>
                        <br/>
                        <p class="date">{{image.date}}</p>
                        <p class="desc">{{image.desc}}</p>
                    </div>
                </div>
            </div>
            <div class="section-container">
                <h3 class="lily-section-title">Almost Winter</h3>
                <div class="image-gallery">
                    <div class="image-card" v-for="image in almostWinter" :key="image.src">
                        <img class="lily-image" :src="require(`../../static/lily-images/${image.src}`)"/>
                        <br/>
                        <br/>
                        <p class="date">{{image.date}}</p>
                        <p class="desc">{{image.desc}}</p>
                    </div>
                </div>
            </div>
            <div class="section-container">
                <h3 class="lily-section-title">Winter 2022</h3>
                <div class="image-gallery">
                    <div class="image-card" v-for="image in winter" :key="image.src">
                        <img class="lily-image" :src="require(`../../static/lily-images/${image.src}`)"/>
                        <br/>
                        <br/>
                        <p class="date">{{image.date}}</p>
                        <p class="desc">{{image.desc}}</p>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
import {earlyDays} from "../../static/lily-image-lists/earlyDays.js";
import {theRise} from "../../static/lily-image-lists/theRise.js";
import {spring} from "../../static/lily-image-lists/spring.js";
import {summer} from "../../static/lily-image-lists/summer.js";
import {almostSchool} from "../../static/lily-image-lists/almostSchool.js";
import {schoolStart} from "../../static/lily-image-lists/schoolStart.js";
import {almostWinter} from "../../static/lily-image-lists/almostWinter.js";
import {winter} from "../../static/lily-image-lists/winter.js";
export default {
    created() {
        document.title = "Liao Zhu - For Lily";
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    mounted() {
        let introContainer = document.getElementById("intro");
        setTimeout(() => {
            introContainer.classList.add("active");
        }, 2250)
    },
    data() {
        return {
            earlyDays: earlyDays,
            theRise: theRise,
            spring: spring,
            summer: summer,
            almostSchool: almostSchool,
            schoolStart: schoolStart,
            almostWinter: almostWinter,
            winter: winter,

        }
    }
}
</script>
<style scoped>

span {
    color: var(--pink);
}
.page-title {
    font-size: 3rem;
}

.lily-inner-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    transition: ease 1.5s;
}
.lily-intro-container {
    z-index: 2;
    background-color: white;
}

.lily-image-container {
    background-color: rgba(18,18,18,255);
    color: white;
    flex-direction: column;
    z-index: 1;
    height: auto;
    top: 0;
    padding-top: 50px;
}

.active {
    transform: translateY(-100%);
}

.section-container {
    width: 100%;
    padding-inline: 25px;
    margin-bottom: 25px;
}

.image-gallery {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
}

.image-gallery::-webkit-scrollbar {
  display: none;
}

.lily-section-title {
    margin-top: 25px;
    margin-bottom: 10px;
}

.image-card {
    background-color: white;
    color: black;
    padding: 15px;
    border-radius: 6px;
}

.lily-image {
    width: 300px;
    height: 400px;
    border-radius: 6px;
    object-fit: cover;
}

@media screen and (max-width: 1200px) {
  #valentines-title {
    font-size: 2rem;
  }
}
</style>