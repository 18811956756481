export const theRise = [
    {
        src: "basketballgame.jpg",
        date: "01/23/2022",
        desc: "HERKY!!!"
    },
    {
        src: "basketballgame2.jpg",
        date: "01/23/2022",
        desc: "What a view"
    },
    {
        src: "hotpot.jpg",
        date: "02/06/2022",
        desc: "My hair 😬"
    },
    {
        src: "hotpot2.jpg",
        date: "02/06/2022",
        desc: "Can't stop eating"
    },
    {
        src: "valentinesboba.jpg",
        date: "02/14/2022",
        desc: "'I love you'"
    },
    {
        src: "valentinesboba2.jpg",
        date: "02/14/2022",
        desc: "Strawberry and matcha (I think it was)"
    },
    {
        src: "valentinesboba3.jpg",
        date: "02/14/2022",
        desc: "RIP camera quality"
    },
    {
        src: "phodate.jpg",
        date: "02/19/2022",
        desc: "First ever pho was amazing"
    },

]