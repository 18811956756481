<template>
    <div class="section full-height" id="song-page">
        <TopbarVue urlExtended backgroundGradient="linear-gradient(to bottom, rgba(136,75,75,255), rgba(78,42,42,255));" />
        <div class="container-flex justify-content-left song-page-header">
            <img src="../../static/images/iowa.png" />
            <div class="column">
                <p class="playlist-category">SONG</p>
                <h1 class="playlist-title">UI Course Catalog</h1>
                <strong class="playlist-creator">Liao Zhu</strong>
            </div>
        </div>
        <MusicNav style="cursor: pointer;" />
        <div class="container-flex justify-content-left song-desc">
            <p>This project was an interview project given to me for a position at my school. It has three versions, a Vue
                version, a vanilla JS version, and a Java version.
                <br />
                <br />
                This was the very first time I had ever used Vue. It utilizes the school's public api endpoints to create a
                clone of what the school's real course search system is like.
                As long as a valid course is entered, the application returns accurate results with locations, times,
                sections, etc. across different semesters throughout numerous years.
                <br />
                <br />
                To try it yourself, go to <a target="_blank"
                    href="https://vueitsproject.netlify.app/">https://vueitsproject.netlify.app/</a> and try entering "Fall
                2022", "CS", and then "2630" for example.
            </p>
        </div>
    </div>
</template>
<script>
import MusicNav from "../components/MusicNav.vue";
import TopbarVue from "../components/TopbarVue.vue";

export default {
    created() {
        document.title = "Liao Zhu - UIowa Course Catalog Clone"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        MusicNav,
        TopbarVue
    },
    methods: {
        togglePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity = 1;
        },
        closePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity = 0;
        },
        toggleAccountDropdown() {
            this.accountIsShowing = !this.accountIsShowing;
            if (this.accountIsShowing) {
                document.getElementById("caret-up").style.display = "flex";
                document.getElementById("caret-down").style.display = "none";
                document.getElementById("account-dropdown-container").style.display = "flex";
            } else {
                document.getElementById("caret-up").style.display = "none";
                document.getElementById("caret-down").style.display = "flex";
                document.getElementById("account-dropdown-container").style.display = "none";
            }
        },
    }

}
</script>
<style scoped>
.section {
    background-image: linear-gradient(to bottom, rgba(78, 42, 42, 255), black);
}

.song-page-header {
    background-image: linear-gradient(to bottom, rgba(136, 75, 75, 255), rgba(78, 42, 42, 255));
}</style>