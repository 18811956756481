export const earlyDays = [
    {
        src: "concert.png",
        date: "10/15/2021",
        desc: "Tobi Lou was okay :|"
    },
    {
        src: "love.jpg",
        date: "11/07/2021",
        desc: "First time going to Des Moines with you!"
    },
    {
        src: "birthday2.jpg",
        date: "11/07/2021",
        desc: "Got to celebrate your 19th"
    },
    {
        src: "birthday3.jpg",
        date: "11/07/2021",
        desc: "Presents"
    },
    {
        src: "avatar.jpg",
        date: "11/23/2021",
        desc: "First haircut pics"
    },
    {
        src: "avatar2.jpg",
        date: "11/23/2021",
        desc: "The last airbender 😱"
    },
    {
        src: "avatar3.png",
        date: "11/23/2021",
        desc: "Nvm it was just Zuko"
    },
    {
        src: "baby.jpg",
        date: "12/25/2003",
        desc: "Really early days"
    },
]