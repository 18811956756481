<template>
    <div class="section full-height" id="song-page">
        <TopbarVue urlExtended backgroundGradient="linear-gradient(to bottom, rgba(114,87,176,255), rgba(67,51,103,255))" />
        <div class="container-flex justify-content-left song-page-header">
            <img src="../../static/images/qit.png" />
            <div class="column">
                <p class="playlist-category">SONG</p>
                <h1 class="playlist-title">QiT</h1>
                <strong class="playlist-creator">Liao Zhu</strong>
            </div>
        </div>
        <MusicNav notarget style="cursor: pointer;" />
        <div class="container-flex justify-content-left song-desc">
            <p>QiT (pronounced "cheat"), was my first ever full stack application using Flask and MySQL. QiT was supposed to
                be a random blog posting, social media, with the incentive to
                be students posting questions and answers with each other. Each post could be liked, commented on, and
                shared with other users. Each user even had their own individual page
                with biographies and profile pictures.
                <br />
                <br />
                Though QiT is not hosted due to Heroku going down, it served a great purpose to me in multiple ways. One, I
                gained a huge insight into how full stack and rest api's worked. This was
                a crucial step for me to reaching my goal of becoming a ⭐10x developer⭐!!! No but in all seriousness,
                second, it helped me realize how I needed to get away from tutorial hell. When building
                QiT, I found myself not really retaining the info, but rather just brainlessly typing away.
                <br />
                <br />
                In order to combat this, I decided to attempt my own full stack app from scratch. This is what lead to
                <RouterLink to="/track/clask">Clask</RouterLink>.
            </p>
        </div>
    </div>
</template>
<script>
import MusicNav from "../components/MusicNav.vue";
import TopbarVue from "../components/TopbarVue.vue";

export default {
    created() {
        document.title = "Liao Zhu - QiT"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        MusicNav,
        TopbarVue
    },
    methods: {
        togglePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity = 1;
        },
        closePlaylistPopup() {
            let playlistPopup = document.getElementById("playlist-popup");
            playlistPopup.style.opacity = 0;
        },
        toggleAccountDropdown() {
            this.accountIsShowing = !this.accountIsShowing;
            if (this.accountIsShowing) {
                document.getElementById("caret-up").style.display = "flex";
                document.getElementById("caret-down").style.display = "none";
                document.getElementById("account-dropdown-container").style.display = "flex";
            } else {
                document.getElementById("caret-up").style.display = "none";
                document.getElementById("caret-down").style.display = "flex";
                document.getElementById("account-dropdown-container").style.display = "none";
            }
        },
    }

}
</script>
<style scoped>
.section {
    background-image: linear-gradient(to bottom, rgba(67, 51, 103, 255), black);
}

.song-page-header {
    background-image: linear-gradient(to bottom, rgba(114, 87, 176, 255), rgba(67, 51, 103, 255));
}
</style>