export const schoolStart = [
    {
        src: "statefairherky.jpg",
        date: "08/12/2022",
        desc: "STATEFAIR!!!!"
    },
    {
        src: "statefair4.jpg",
        date: "08/12/2022",
        desc: "We were super high up"
    },
    {
        src: "statefair2.jpg",
        date: "08/12/2022",
        desc: "Apple cider"
    },
    {
        src: "statefair1.jpg",
        date: "08/12/2022",
        desc: "I was so parched"
    },
    {
        src: "statefair3.jpg",
        date: "08/12/2022",
        desc: "Cute"
    },
    {
        src: "dairy.jpg",
        date: "09/09/2022",
        desc: "Dairy Queen date"
    },
    {
        src: "dairy2.jpg",
        date: "09/09/2022",
        desc: "The walk there was nice"
    },
    {
        src: "dairy3.jpg",
        date: "09/09/2022",
        desc: "So photogenic"
    },
    {
        src: "treepic.jpg",
        date: "10/21/2022",
        desc: "😳"
    },
    {
        src: "treepic2.jpg",
        date: "10/21/2022",
        desc: "Fall pics next to the pentacrest trees"
    },
    {
        src: "treepic3.jpg",
        date: "10/21/2022",
        desc: "Ope"
    },
    {
        src: "treepic4.jpg",
        date: "10/21/2022",
        desc: "Pose"
    },
    {
        src: "treepic5.jpg",
        date: "10/21/2022",
        desc: "Pose"
    },
    {
        src: "treepic6.jpg",
        date: "10/21/2022",
        desc: "Beautiful"
    },
    {
        src: "treepic7.jpg",
        date: "10/21/2022",
        desc: "Reflection quality better than my camera"
    },
    {
        src: "tippie1.jpg",
        date: "10/28/2022",
        desc: "Amazeballs"
    },
    {
        src: "concert2.jpg",
        date: "10/28/2022",
        desc: "Album cover worthy"
    },
    {
        src: "concert3.jpg",
        date: "10/28/2022",
        desc: "Chille"
    },
]