<template>
    <div class="section full-height" id="song-page">
        <TopbarVue urlExtended
            backgroundGradient="linear-gradient(to bottom, rgba(40, 10, 80, 255), rgba(34, 4, 92, 255));" />
        <div class="container-flex justify-content-left song-page-header">
            <img src="../../static/images/eduquest.png" />
            <div class="column">
                <p class="playlist-category">SONG</p>
                <h1 class="playlist-title">EduQuest</h1>
                <strong class="playlist-creator">Liao Zhu</strong>
            </div>
        </div>
        <MusicNav link="https://devpost.com/software/eduquest-9eztjl" />
        <div class="container-flex justify-content-left song-desc">
            <p>
                <a href="https://devpost.com/software/eduquest-9eztjl" target="_blank">EduQuest</a> was my first ever
                hackathon award winning project. I had a ton of fun working alongside my friends <a
                    href="https://www.lukemoenning.com/" target="_blank">Luke M.</a>,
                and <a href="https://github.com/Adi-M02" target="_blank">Adi M.</a>
                <br />
                <br />
                We got <strong>2nd place</strong> overall amongst the 90+
                teams that participated as well as the <strong>Innovator's Achievement Award</strong>.
                <br />
                <br />
                EduQuest is an AI powered tutor based on the teaching principles of <a
                    href="/experience/mathnasium-instructor">Mathnasium</a>. Using OpenAI we generate unique questions for
                students grades 1-6 to answer. The answers are recursively generated based on the questions given. The
                student can also ask for hints and the AI will provide a hint based on the student's previous
                answers. Parental support was a priority, thus parent accounts can be created to monitor their child's
                progress and view their child's reports.
                <br />
                <br />
                This was the first time I've gotten to experience how AWS functioned alongside ReactJS. Thank you Luke M.
                for showing me your AWS skills and for helping me out with the backend.
            </p>
        </div>
    </div>
</template>
<script>
import MusicNav from "../components/MusicNav.vue";
import TopbarVue from "../components/TopbarVue.vue";

export default {
    created() {
        document.title = "Liao Zhu - EduQuest"
        // scroll to top of page on load
        window.scrollTo(0, 0);
    },
    components: {
        MusicNav,
        TopbarVue
    },
}
</script>
<style scoped>
.section {
    background-image: linear-gradient(to bottom, rgba(34, 4, 92, 255), black);
}

.song-page-header {
    background-image: linear-gradient(to bottom, rgba(40, 10, 80, 255), rgba(34, 4, 92, 255));
}
</style>