import { createWebHistory, createRouter } from "vue-router"
import Home from "@/components/HomeVue.vue"
// import Error from "@/components/ErrorVue.vue"
import Profile from '@/components/ProfileVue.vue'
import Projects from "@/components/ProjectsVue.vue";
import Content from "@/components/ContentVue.vue";

import TikTokVideoGenerator from "@/pages/TikTokVideoGenerator.vue";
import Clask from "@/pages/ClaskVue.vue";
import Portfolio from "@/pages/MyPortfolio.vue";
import Qit from "@/pages/QitVue.vue";
import UIowaCatalog from "@/pages/CatalogClone.vue";
import SortingVisualizer from "@/pages/SortingVisualizer.vue";
import Statipy from "@/pages/StatipyVue.vue";
import SayIt from "@/pages/SayIt.vue";
import UIGrades from "@/pages/UIGrades.vue";
import Courser from "@/pages/CourserVue.vue";
import EduQuest from "@/pages/EduQuest.vue";

import Mathnasium from "@/pages/MathnasiumInstructor.vue";
import UIowaWebIntern from "@/pages/UIowaWebIntern.vue";
import ITSIntern from "@/pages/ITSIntern.vue";
import Collins from "@/pages/CollinsVue.vue";

import Acm from "@/pages/AcmVue.vue";
import Robotics from "@/pages/RoboticsVue.vue";

import Search from "@/components/Search.vue";

import Lily from "@/pages/LilyVue.vue"

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: '/:pathMatch(.*)*',
		component: () => import('@/components/ErrorVue.vue'),
		meta: {
			hideNav: true,
		}
	},
	{
		path: "/user",
		name: "Profile",
		component: Profile
	},
	{
		path: "/playlist/projects",
		name: "Projects",
		component: Projects
	},
	{
		path: "/playlist/content",
		name: "Content",
		component: Content
	},
	// project indv pages (figure out dynamic routing)
	{
		path: "/track/courser",
		name: "Courser",
		component: Courser
	},
	{
		path: "/track/eduquest",
		name: "EduQuest",
		component: EduQuest
	},
	{
		path: "/track/sayit",
		name: "SayIT!",
		component: SayIt
	},
	{
		path: "/track/uigrades",
		name: "UIGrades",
		component: UIGrades
	},
	{
		path: "/track/tiktok-video-generator",
		name: "TikTokVideoGenerator",
		component: TikTokVideoGenerator
	},
	{
		path: "/track/clask",
		name: "Clask",
		component: Clask
	},
	{
		path: "/track/my-portfolio",
		component: Portfolio
	},
	{
		path: "/track/qit",
		component: Qit
	},
	{
		path: "/track/uiowa-course-search-clone",
		component: UIowaCatalog
	},
	{
		path: "/track/sorting-visualizer",
		component: SortingVisualizer
	},
	{
		path: "/track/statipy",
		component: Statipy
	},
	// Experience Pages
	{
		path: "/experience/mathnasium-instructor",
		component: Mathnasium
	},
	{
		path: "/experience/uiowa-web-intern",
		component: UIowaWebIntern,
	},
	{
		path: "/experience/its-ais-swe-intern",
		component: ITSIntern,
	},
	{
		path: "/experience/collins-software-engineer-intern",
		component: Collins
	},
	/* ==== Extracurriculars ===== */
	{
		path: "/playlist/acm",
		component: Acm
	},
	{
		path: "/playlist/robotics",
		component: Robotics
	},
	{
		path: "/search",
		component: Search
	},
	/* ===== Extra ====== */
	{
		path: "/for-lily",
		component: Lily,
		meta: {
			hideNav: true,
		}
	}
]


const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router