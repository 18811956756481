export const almostWinter = [
    {
        src: "bakery.jpg",
        date: "11/05/2022",
        desc: "I forgot the name of this place"
    },
    {
        src: "bakery2.jpg",
        date: "11/05/2022",
        desc: "But it was good"
    },
    {
        src: "20bday3.jpg",
        date: "11/06/2022",
        desc: "Parents came to celebrate your bday"
    },
    {
        src: "20bday2.jpg",
        date: "11/06/2022",
        desc: "You were sad but we saw them soon"
    },
    {
        src: "20bday.jpg",
        date: "11/06/2022",
        desc: "Presents!!!"
    },
    {
        src: "sleep7.jpg",
        date: "11/10/2022",
        desc: "Sleepy"
    },
    {
        src: "sleep8.jpg",
        date: "11/10/2022",
        desc: "Professional"
    },
    {
        src: "window.jpg",
        date: "11/30/2022",
        desc: "Cutie"
    },
]